import { createRouter, createWebHistory } from 'vue-router'
import authRouter from '../modules/auth/router'
import eventsRouter from '../modules/events/router'
import proposalsRouter from '../modules/proposals/router'
import isAuthenticatedGuard from '@/modules/auth/router/auth-guard'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/HomeView.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    ...authRouter
  },
  {
    path: '/events',
    name: 'events',
    beforeEnter: [ isAuthenticatedGuard ],
    ...eventsRouter
  },
  {
    path: '/proposals',
    name: 'proposals',
    beforeEnter: [ isAuthenticatedGuard ],
    ...proposalsRouter
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
