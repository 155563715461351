export default () => ({

    proposalsList: [],
    proposalsShown: {
      type: Object,
      default: null
    },
    proposalsLoaded:{
        type: Boolean,
        default:false
    },
    proposalsError:{
        type: Boolean,
        default:false
    }
  
  
  })