

export const eventsList = ( state ) => {
    return state.eventsList
   }

   export const eventsLoaded = ( state ) => {
    try{
        const result= state.eventsLoaded
        return result
    }
    catch (error){
        return false
    }
    
   }

   export const getActiveEvent = (state) => {
    return state.eventsList[state.activeEvent_index]
    //return state.activeEvent
   }
   export const getActiveZone = (state) => {
    return state.eventsList[state.activeEvent_index]._zones[state.activeZone_index]
    //return state.activeEvent
   }
   export const getActiveBlok = (state) => {
    return state.eventsList[state.activeEvent_index]._zones[state.activeZone_index].bloks[state.activeBlok_index]
    //return state.activeEvent
   }

   export const getActiveEvent_index = (state) => {
    return state.activeEvent_index
    //return state.activeEvent
   }
   export const getActiveZone_index = (state) => {
    return state.activeZone_index
    //return state.activeEvent
   }
   export const getActiveBlok_index = (state) => {
    return state.activeBlok_index
    //return state.activeEvent
   }


   //Estas funciones no van a utilizarse
   
   export const getSingleEvent = (state) => {
    
    return state.singleEvent
   }
   export const singleEventLoaded = (state) => {
    return state.singleEventLoaded
   }
   