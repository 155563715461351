

export default {

    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '@/modules/events/layouts/EventsLayout.vue'),
    children: [
        {
            path: '/events',
            name: 'view-events',
            component: () => import(/* webpackChunkName: "view-events" */ '@/modules/events/views/viewEvents.vue'),
        },
        {
            path: '/events/evento',
            name: 'view-event-single',
            component: () => import(/* webpackChunkName: "view-event-single" */ '@/modules/events/views/viewEventSingle.vue'),
            
        },
        {
            path: '/events/evento/zona',
            name: 'view-zone-single',
            component: () => import(/* webpackChunkName: "view-zone-single" */ '@/modules/events/views/viewZoneSingle.vue'),
            
        },
        {
            path: '/events/evento/zona/blok',
            name: 'view-blok-single',
            component: () => import(/* webpackChunkName: "view-event-single" */ '@/modules/events/views/viewBlokSingle.vue'),
            
        }
        
    ]
}