
import Vue from 'vue'

export const getEvents = (state, eventsList) => {

    console.log(eventsList)
    if (eventsList) state.eventsLoaded = true
    const basicPromoterDashboardData = {
        BannerDataInc: "Error",
        BannerDataIncome: "ERROR",
        BannerDataOcupation: "ERROR",
        BannerDataTiketsSold: "ERROR",
        EventColor: "#CACACA",
        PlaceProvinceName: "ERROR",
        imgHeader: null,
        imgHeaderUrl: null,
        imgThumbnail: null,
        imgThumbnailUrl: null
    }
    //Comprobar eventos para conseguir datos "fake"
    try{
    for (let i = 0; i < eventsList.length; i++) {

        if(!eventsList[i].promoterDashboardData)
        {
            eventsList[i].promoterDashboardData= basicPromoterDashboardData
        }
        if (eventsList[i].id == "YWAC4UfrpWVI6SPSHndr") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.EventColor = "#FFA753"

        }
        if (eventsList[i].id == "kOprNGTB5uOIUCQMyBpw") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 15
            eventsList[i].promoterDashboardData.BannerDataIncome = 1550
            eventsList[i].promoterDashboardData.BannerDataOcupation = 75
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 95
            eventsList[i].promoterDashboardData.EventColor = "#3812f6"
            eventsList[i].name = "Nat Simons"

        }
        if (eventsList[i].id == "b6jCGSkdGzBn6sx8RAb7") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 65
            eventsList[i].promoterDashboardData.BannerDataIncome = 25365
            eventsList[i].promoterDashboardData.BannerDataOcupation = 100
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 3500
            eventsList[i].promoterDashboardData.EventColor = "#ed1e79"
            eventsList[i].name = "Pussy Riot"

        }
        if (eventsList[i].id == "P6nbZc2yvUScNvfmOpVr") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 25
            eventsList[i].promoterDashboardData.BannerDataIncome = 10550
            eventsList[i].promoterDashboardData.BannerDataOcupation = 95
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 1000
            eventsList[i].promoterDashboardData.EventColor = "#2E94FA"
            eventsList[i].name = "Lee Rocker of The Stray Cats"

        }
        if (eventsList[i].id == "kWw9ilk4IezPS14WHv7A") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 75
            eventsList[i].promoterDashboardData.BannerDataIncome = 50486
            eventsList[i].promoterDashboardData.BannerDataOcupation = 100
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 3500
            eventsList[i].promoterDashboardData.EventColor = "#cacaca"
            eventsList[i].name = "Evento con Proposals"

        }
        if (eventsList[i].id == "h8izoRaN9BaZ4Ej8D6OI") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 5
            eventsList[i].promoterDashboardData.BannerDataIncome = 625
            eventsList[i].promoterDashboardData.BannerDataOcupation = 35
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 45
            eventsList[i].promoterDashboardData.EventColor = "#ff8600"
            eventsList[i].name = "Sobrezero"

        }

        if (eventsList[i].id == "LgIvTNLk8QGyKtESj3B2") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 0
            eventsList[i].promoterDashboardData.BannerDataIncome = 150
            eventsList[i].promoterDashboardData.BannerDataOcupation = 5
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 10
            eventsList[i].promoterDashboardData.EventColor = "#04af32"
            eventsList[i].name = "Moscú Babies"

        }
        if (eventsList[i].id == "3ttlYrBVeWrVPRmG4Dtf") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 0
            eventsList[i].promoterDashboardData.BannerDataIncome = 150
            eventsList[i].promoterDashboardData.BannerDataOcupation = 5
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 10
            eventsList[i].promoterDashboardData.EventColor = "#ff0000"
            eventsList[i].name = "Diables"

        }

        if (eventsList[i].id == "iUhA5iQDP8Rifl1BcENz") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 0
            eventsList[i].promoterDashboardData.BannerDataIncome = 150
            eventsList[i].promoterDashboardData.BannerDataOcupation = 5
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 10
            eventsList[i].promoterDashboardData.EventColor = "#670a52"
            eventsList[i].name = "XCESE"

        }
        if (eventsList[i].id == "5dfVbALRs851BmDC291w") {
            //eventsList[i].promoterDashboardData = basicPromoterDashboardData
            eventsList[i].promoterDashboardData.BannerDataInc = 0
            eventsList[i].promoterDashboardData.BannerDataIncome = 150
            eventsList[i].promoterDashboardData.BannerDataOcupation = 5
            eventsList[i].promoterDashboardData.BannerDataTiketsSold = 10
            eventsList[i].promoterDashboardData.EventColor = "#b54b05"
            eventsList[i].name = "EXTREMODURO"

        }
        
        
        
        console.log(eventsList[i])
    }}
    catch(error){
        console.log(error)
    }

    state.eventsList = eventsList
}

export const setActiveEvent = (state, { event, event_index }) => {

    //state.activeEvent = event
    state.activeEvent_index = event_index
}

export const setActiveZone = (state, { zone_index }) => {

    //state.activeEvent = event
    state.activeZone_index = zone_index
}

export const setActiveBlok = (state, { blok_index }) => {

    //state.activeEvent = event
    state.activeBlok_index = blok_index
}


export const getSingleEvent = (state, event) => {

    if (event) {
        state.singleEventLoaded = true
        state.eventsList[state.activeEvent_index]._extendedDataForEventLoaded = true
        //ToDo: Añadir todos los campos que necesito al evento del array eventsList
        state.eventsList[state.activeEvent_index]._qrVisibleTime = event.qrVisibleTime
        state.eventsList[state.activeEvent_index]._beginSaleDate = event.beginSaleDate
        state.eventsList[state.activeEvent_index]._beginPublishDate = event.beginPublishDate
        state.eventsList[state.activeEvent_index]._endPublishDate = event.endPublishDate
        state.eventsList[state.activeEvent_index]._eventBeginTime = event.eventBeginTime
        state.eventsList[state.activeEvent_index]._doorOpenTime = event.doorOpenTime
        state.eventsList[state.activeEvent_index]._totalAmountCollected = event.totalAmountCollected
        state.eventsList[state.activeEvent_index]._zones = event.zones
        state.eventsList[state.activeEvent_index]._session0id = event.id
        state.eventsList[state.activeEvent_index]._session0PlaceImageUrl = event.placeImageUrl
        let globalCapacity = 0
        event.zones.forEach(function (zone, zone_index) {
            globalCapacity += zone.capacity
        })
        state.eventsList[state.activeEvent_index]._capacity = globalCapacity
    }
    //state.singleEvent = event
}

export const getPlace = (state, { place, event_id }) => {

    //Buscamos el índice de evento para añadir los datos del place
    let index = -1
    for (let i = 0; i < state.eventsList.length; i++) {
        if (state.eventsList[i].id == event_id) index = i
    }
    state.eventsList[index].placeLoadedOK = true
    state.eventsList[index].placeLoaded = place
    //Object.defineProperty(selectedEvent, 'placeLoaded', { value: place})

}

export const getEventSales = (state, { zones, event_id }) => {

    try {
        //Buscamos el índice de evento para añadir las ventas
        let index = -1
        for (let i = 0; i < state.eventsList.length; i++) {
            if (state.eventsList[i].id == event_id) index = i
        }
        state.eventsList[index]._salesLoadedOK = true
        state.eventsList[index]._salesLoadedError = false
        state.eventsList[index]._salesLoaded = zones
        //Mantenemos el conteo global del evento
        let globalWinnerProposalsCount = 0
        let globalWinnerTicketsCount = 0
        let globalWinnerAmount = 0
        let globalTiketblokAmount = 0
        //Repartir las ventas por las zonas

        zones.forEach(function (zone, zone_index) {
            state.eventsList[index]._zones[zone_index].startingPrice = zone.startingPrice
            let winnerProposalsCount = 0
            let winnerTicketsCount = 0
            let winnerAmount = 0
            let tiketblokAmount = 0
            const bloks = zone.bloks
            bloks.forEach(function (block) {
                winnerProposalsCount += block.summary.winnerProposalsCount
                winnerTicketsCount += block.summary.winnerTicketsCount
                winnerAmount += block.summary.winnerAmount
                tiketblokAmount += block.summary.tiketblokAmount
                //Añadimos la información necesaria a "Zones"
                //Buscamos este block en state.eventsList[index]._zones[zone_index] y añadimos 3 campos:
                // Entradas vendidas
                //Facturación
                //Precio medio
                let j = 0
                let found = false

                while (!found || j < state.eventsList[index]._zones[zone_index].bloks.length) {
                    if (state.eventsList[index]._zones[zone_index].bloks[j].id == block.id) {
                        //Hemos encontrado el block, añadimos los datos
                        state.eventsList[index]._zones[zone_index].bloks[j]._entradasVendidas = block.summary.winnerTicketsCount
                        state.eventsList[index]._zones[zone_index].bloks[j]._facturacion = block.summary.tiketblokAmount
                        state.eventsList[index]._zones[zone_index].bloks[j]._precioMedio = block.summary.averagePrice
                        found = true
                    }
                    j++
                }

            })
            //Ahora creamos dentro de la zona el objeto para mostrar los blocks en la vista de zona
            state.eventsList[index]._zones[zone_index]._bloksShow = {}
            state.eventsList[index]._zones[zone_index]._bloksShow.itemsCount = state.eventsList[index]._zones[zone_index].bloks.length
            state.eventsList[index]._zones[zone_index]._bloksShow.itemsPerPage = 10
            state.eventsList[index]._zones[zone_index]._bloksShow.pageCurrent = 1
            state.eventsList[index]._zones[zone_index]._bloksShow.pageCount = Math.floor(state.eventsList[index]._zones[zone_index]._bloksShow.itemsCount / state.eventsList[index]._zones[zone_index]._bloksShow.itemsPerPage) + 1
            state.eventsList[index]._zones[zone_index]._bloksShow.bloks = state.eventsList[index]._zones[zone_index].bloks.slice(0, (state.eventsList[index]._zones[zone_index]._bloksShow.itemsPerPage))

            //Datos de venta
            state.eventsList[index]._zones[zone_index]._proposals = winnerProposalsCount
            globalWinnerProposalsCount += winnerProposalsCount
            state.eventsList[index]._zones[zone_index]._tickets = winnerTicketsCount
            globalWinnerTicketsCount += winnerTicketsCount
            state.eventsList[index]._zones[zone_index]._winnerAmount = winnerAmount
            globalWinnerAmount += winnerAmount
            state.eventsList[index]._zones[zone_index]._tiketblokAmount = tiketblokAmount
            globalTiketblokAmount += tiketblokAmount
            //Porcentaje
            state.eventsList[index]._zones[zone_index]._precioMedio = tiketblokAmount / winnerTicketsCount
            state.eventsList[index]._zones[zone_index]._porcentajeOcupacion = winnerTicketsCount / state.eventsList[index]._zones[zone_index].capacity * 100
            state.eventsList[index]._zones[zone_index]._porcentajeIncremento = ((tiketblokAmount / winnerTicketsCount) - state.eventsList[index]._zones[zone_index].startingPrice) / state.eventsList[index]._zones[zone_index].startingPrice * 100


            //Flags de carga
            state.eventsList[index]._zones[zone_index]._salesLoadedOK = true
            state.eventsList[index]._zones[zone_index]._salesLoadedError = false



        })
        state.eventsList[index]._proposals = globalWinnerProposalsCount
        state.eventsList[index]._tickets = globalWinnerTicketsCount
        state.eventsList[index]._winnerAmount = globalWinnerAmount
        state.eventsList[index]._tiketblokAmount = globalTiketblokAmount

    }
    catch (error) {
        console.log(error)
        state.eventsList[index]._salesLoadedOK = true
        state.eventsList[index]._salesLoadedError = true
        state.eventsList[index]._salesLoaded = zones
        zones.forEach(function (zone, zone_index) {
            state.eventsList[index]._zones[zone_index]._salesLoadedOK = true
            state.eventsList[index]._zones[zone_index]._salesLoadedError = true
        })
    }
}


export const getEventSalesError = (state, event_id) => {

    try {
        //Buscamos el índice de evento para añadir las ventas
        let index = -1
        for (let i = 0; i < state.eventsList.length; i++) {
            if (state.eventsList[i].id == event_id) index = i
        }
        state.eventsList[index]._salesLoadedOK = true
        state.eventsList[index]._salesLoadedError = true
        //Repartir las ventas por las zonas

        state.eventsList[index]._zones.forEach(function (zone, zone_index) {
            zone.startingPrice = 0
            zone._salesLoadedOK = true
            zone._salesLoadedError = true

        })
    }
    catch (error) {
        console.log(error)
    }
}

export const getEventProposals = (state, { proposals, event_id }) => {

    //Buscamos el índice de evento para añadir las propuestas
    let indexEvent = -1
    for (let i = 0; i < state.eventsList.length; i++) {
        if (state.eventsList[i].id == event_id) indexEvent = i
    }
    console.log("Index Event: " + indexEvent)
    //Ahora creamos un objeto con la proposals susceptibles de ser mostradas "Purchased" y "Rejected" para el evento
    state.eventsList[indexEvent]._ProposalsValid = []
    console.log("Event content: " + state.eventsList)
    console.log("Event content: " + state.eventsList[indexEvent])
    //Ahora creamos un objeto con la proposals susceptibles de ser mostradas "Purchased" y "Rejected" para cada zona del evento
    state.eventsList[indexEvent]._zones.forEach(function (zone){
        zone._ProposalsValid = []
    })
    // for (let z = 0; z < state.eventsList[indexEvent]._zones.length; z++) {
    //     state.eventsList[indexEvent]._zones[z]._ProposalsValid = []
    // }

    let mayor = 0
    let inicio = 0
    let inferior = 0
    let total = 0
    proposals.forEach(function (proposal, index) {
        if (proposal.status == "Purchased" || proposal.status == "Rejected") {
            const finalPrice = proposal.finalPrice
            //Tenemos que averiguar en qué zona estamos
            const zoneId = proposal.zoneId
            //Ahoa averiguamos el índice de esa zona en la colección
            let indexZone = -1
            for (let j = 0; j < state.eventsList[indexEvent]._zones.length; j++) {
                if (state.eventsList[indexEvent]._zones[j].id == zoneId) indexZone = j
            }
            //Agregamos esta propuesta al objeto ProposalsValid del Evento
            console.log('Event index: ' + indexEvent + ' / Zone index: ' + indexZone)
            if (indexZone >= 0 && indexEvent >= 0) {
                state.eventsList[indexEvent]._ProposalsValid.push(proposal)
                //Agregamos esta propuesta al objeto ProposalsValid de la zona
                state.eventsList[indexEvent]._zones[indexZone]._ProposalsValid.push(proposal)
            }

            //Continuamos con el flujo de suma de valores de proposals "Purchased"
            if (proposal.status == "Purchased") {
                const precio_salida = state.eventsList[indexEvent]._zones[indexZone].price
                //Comprobar y actualizar datos de zona
                if (!state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInferior) state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInferior = 0
                if (!state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInicio) state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInicio = 0
                if (!state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteMayor) state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteMayor = 0
                if (!state.eventsList[indexEvent]._zones[indexZone]._ventasTotales) state.eventsList[indexEvent]._zones[indexZone]._ventasTotales = 0
                if (finalPrice < precio_salida) {
                    inferior += proposal.ticketsCount
                    state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInferior += proposal.ticketsCount
                }
                if (finalPrice == precio_salida) {
                    inicio += proposal.ticketsCount
                    state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteInicio += proposal.ticketsCount
                }
                if (finalPrice > precio_salida) {
                    mayor += proposal.ticketsCount
                    state.eventsList[indexEvent]._zones[indexZone]._ventasLimiteMayor += proposal.ticketsCount
                }
                total += proposal.ticketsCount
                state.eventsList[indexEvent]._zones[indexZone]._ventasTotales += proposal.ticketsCount

                console.log('(' + index + ') ' + precio_salida + '->' + finalPrice + ' tickets: ' + proposal.ticketsCount + ' - TOTAL: ' + total)
            }
        }


    })
    state.eventsList[indexEvent]._ventasTotales = total
    state.eventsList[indexEvent]._ventasLimiteMayor = mayor
    state.eventsList[indexEvent]._ventasLimiteInicio = inicio
    state.eventsList[indexEvent]._ventasLimiteInferior = inferior


    state.eventsList[indexEvent]._proposalsLoadedDone = true
    state.eventsList[indexEvent]._proposalsLoadedError = false
    state.eventsList[indexEvent]._proposalsLoaded = proposals

    //Creamos las vistas paginadas para el evento y sus zonas
    state.eventsList[indexEvent]._ProposalsShow = {}
    state.eventsList[indexEvent]._ProposalsShow.itemsCount = state.eventsList[indexEvent]._ProposalsValid.length
    state.eventsList[indexEvent]._ProposalsShow.itemsPerPage = 10
    state.eventsList[indexEvent]._ProposalsShow.pageCurrent = 1
    state.eventsList[indexEvent]._ProposalsShow.pageCount = Math.floor(state.eventsList[indexEvent]._ProposalsShow.itemsCount / state.eventsList[indexEvent]._ProposalsShow.itemsPerPage) + 1
    state.eventsList[indexEvent]._ProposalsShow.proposals = state.eventsList[indexEvent]._ProposalsValid.slice(0, (state.eventsList[indexEvent]._ProposalsShow.itemsPerPage))

    for (let z = 0; z < state.eventsList[indexEvent]._zones.length; z++) {
        state.eventsList[indexEvent]._zones[z]._ProposalsShow = {}
        state.eventsList[indexEvent]._zones[z]._ProposalsShow.itemsCount = state.eventsList[indexEvent]._zones[z]._ProposalsValid.length
        state.eventsList[indexEvent]._zones[z]._ProposalsShow.itemsPerPage = 10
        state.eventsList[indexEvent]._zones[z]._ProposalsShow.pageCurrent = 1
        state.eventsList[indexEvent]._zones[z]._ProposalsShow.pageCount = Math.floor(state.eventsList[indexEvent]._zones[z]._ProposalsShow.itemsCount / state.eventsList[indexEvent]._zones[z]._ProposalsShow.itemsPerPage) + 1
        state.eventsList[indexEvent]._zones[z]._ProposalsShow.proposals = state.eventsList[indexEvent]._zones[z]._ProposalsValid.slice(0, (state.eventsList[indexEvent]._zones[z]._ProposalsShow.itemsPerPage))

    }
}

export const setEventProposalsError = (state, { event_id }) => {

    //Buscamos el índice de evento para añadir las propuestas
    let indexEvent = -1
    for (let i = 0; i < state.eventsList.length; i++) {
        if (state.eventsList[i].id == event_id) indexEvent = i
    }
    console.log("Index Event: " + indexEvent)
    //Ahora creamos un objeto con la proposals susceptibles de ser mostradas "Purchased" y "Rejected" para el evento
    
    state.eventsList[indexEvent]._proposalsLoadedDone = true
    state.eventsList[indexEvent]._proposalsLoadedError = true
    state.eventsList[indexEvent]._proposalsLoaded = null
}

export const paginationBloks = (state, { event_index, zone_index, inc }) => {

    console.log('Pagination(mutation): ' + event_index + ' , ' + zone_index + ' -> ' + inc)
    if (state.eventsList[event_index]._zones[zone_index]._bloksShow.pageCurrent + inc > 0 && state.eventsList[event_index]._zones[zone_index]._bloksShow.pageCurrent + inc <= state.eventsList[event_index]._zones[zone_index]._bloksShow.pageCount) {
        state.eventsList[event_index]._zones[zone_index]._bloksShow.pageCurrent += inc
        //Cargamos la nueva lista
        const firstItem = (state.eventsList[event_index]._zones[zone_index]._bloksShow.pageCurrent * state.eventsList[event_index]._zones[zone_index]._bloksShow.itemsPerPage) - state.eventsList[event_index]._zones[zone_index]._bloksShow.itemsPerPage
        const lastItem = firstItem + state.eventsList[event_index]._zones[zone_index]._bloksShow.itemsPerPage
        state.eventsList[event_index]._zones[zone_index]._bloksShow.bloks = state.eventsList[event_index]._zones[zone_index].bloks.slice(firstItem, lastItem)
    }
}

export const paginationProposals = (state, { event_index, zone_index, inc }) => {

    console.log('Pagination(mutation): ' + event_index + ' , ' + zone_index + ' -> ' + inc)
    if (zone_index > -1) {
        console.log('(mutation): Es Zona')
        if (state.eventsList[event_index]._zones[zone_index]._ProposalsShow.pageCurrent + inc > 0 && state.eventsList[event_index]._zones[zone_index]._ProposalsShow.pageCurrent + inc <= state.eventsList[event_index]._zones[zone_index]._ProposalsShow.pageCount) {
            state.eventsList[event_index]._zones[zone_index]._ProposalsShow.pageCurrent += inc
            //Cargamos la nueva lista
            const firstItem = (state.eventsList[event_index]._zones[zone_index]._ProposalsShow.pageCurrent * state.eventsList[event_index]._zones[zone_index]._ProposalsShow.itemsPerPage) - state.eventsList[event_index]._zones[zone_index]._ProposalsShow.itemsPerPage
            const lastItem = firstItem + state.eventsList[event_index]._zones[zone_index]._ProposalsShow.itemsPerPage
            state.eventsList[event_index]._zones[zone_index]._ProposalsShow.proposals = state.eventsList[event_index]._zones[zone_index]._ProposalsValid.slice(firstItem, lastItem)
        }
    }
    else {
        console.log('(mutation): Es Evento')
        if (state.eventsList[event_index]._ProposalsShow.pageCurrent + inc > 0 && state.eventsList[event_index]._ProposalsShow.pageCurrent + inc <= state.eventsList[event_index]._ProposalsShow.pageCount) {
            state.eventsList[event_index]._ProposalsShow.pageCurrent += inc
            //Cargamos la nueva lista
            const firstItem = (state.eventsList[event_index]._ProposalsShow.pageCurrent * state.eventsList[event_index]._ProposalsShow.itemsPerPage) - state.eventsList[event_index]._ProposalsShow.itemsPerPage
            const lastItem = firstItem + state.eventsList[event_index]._ProposalsShow.itemsPerPage
            state.eventsList[event_index]._ProposalsShow.proposals = state.eventsList[event_index]._ProposalsValid.slice(firstItem, lastItem)
        }

    }

}