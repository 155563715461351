
import authApi from '@/api/authApi'
import router from '@/modules/auth/router'




export const getEvents = async ({ commit }) => {

    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const id = localStorage.getItem('id')
    if(!accessToken) {
        //commit('logout')
        commit('auth/logout', null, { root: true })
        router.push({ name: 'auth' })
        return { ok: false, message: 'No hay token'}
    }

    try {
        console.log ('Getting Events...')
        const config = { 
            headers: {"Authorization" : `Bearer ${accessToken}`},
            params: {"limit" : "10"} //,"status" : "Active"}
          }
          //?promoter=3Ovf7CurAs6aSmkvy6u1
        const { data } = await authApi.get('events',config)
        data.data .forEach( function(event){
            // Object.defineProperty(event, 'placeLoadedOK', { value: false})
            // Object.defineProperty(event, 'placeLoaded', { value: null})
            event.placeLoadedOK = false
            event.placeLoaded = null
         })
		commit('getEvents', ( data.data ))
        return { ok: true, message: "OK"}

    } catch(error) {
        console.log("getEvents - error -> logout")
        console.log(error)
        commit('auth/logout', null, { root: true })
        return { ok: false, message: "ERROR"}
    }

}

export const setActiveEvent = ({commit}, { event, event_index }) => {    

    commit('setActiveEvent', { event, event_index })
}

export const setActiveZone = ({commit}, { zone_index }) => {    

    commit('setActiveZone', { zone_index })
}
export const setActiveBlok = ({commit}, { blok_index }) => {    

    commit('setActiveBlok', { blok_index })
}




export const getSingleEvent = async ({ commit }, id) => {

    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')

    if(!accessToken) {
        commit('auth/logout', null, { root: true })
        router.push({ name: 'auth' })
        return { ok: false, message: 'No hay token'}
    }

    try {
        console.log ('Getting Single Event...(' + id + ')')
        const config = { 
            headers: {"Authorization" : `Bearer ${accessToken}`}
          }
        const { data } = await authApi.get('events/' + id,config)
        const { sessions } = data.data
        
		commit('getSingleEvent', ( sessions[0] ))
        return { ok: true, message: "OK"}
        

    } catch(error) {
        console.log("getSingleEvent - error -> logout")
        console.log(error)
        commit('auth/logout', null, { root: true })
        return { ok: false, message: "ERROR"}
    }

}


export const getPlace = async ({ commit }, {place_id, event_id}) => {

    const accessToken = localStorage.getItem('accessToken')
    if(!accessToken) {
        commit('auth/logout', null, { root: true })
        router.push({ name: 'auth' })
        return { ok: false, message: 'No hay token'}
    }

    try {
        const config = { 
            headers: {"Authorization" : `Bearer ${accessToken}`}
          }
          const { data } = await authApi.get('places/' + place_id,config)
        const place = data.data
		commit('getPlace', {place, event_id})
        

    } catch(error) {
        console.log("getPlace - error -> logout")
        console.log(error)
        commit('auth/logout', null, { root: true })        
        return { ok: false, message: "ERROR"}
    }

}



export const getEventSales = async ({ commit }, event_id) => {

    const accessToken = localStorage.getItem('accessToken')

    if(!accessToken) {
        commit('auth/logout', null, { root: true })
        router.push({ name: 'auth' })
        return { ok: false, message: 'No hay token'}
    }

    try {
        console.log ('Getting Sales for Event...(' + event_id + ')')
        console.log ('Inicio consulta')
        const timer_inicio_consulta = window.performance.now()
        const config = { 
            headers: {"Authorization" : `Bearer ${accessToken}`}
          }
        const { data } = await authApi.get('sale/' + event_id, config)
        const { sessions } = data.data
        console.log ('Fin consulta')
        const timer_fin_consulta = window.performance.now()
        console.log ('Tiempo empleado para cargar SALES: ' + (timer_fin_consulta - timer_inicio_consulta))
        const zones = sessions[0].zones
		commit('getEventSales', ( { zones, event_id } ))
        return { ok: true, message: "OK"}
        

    } catch(error) {
        console.log("getEventSales - error -> actualizamos zonas")
        console.log(error)
        //commit('auth/logout', null, { root: true })
        //Actualizar el error en todas las zonas
        //commit('getEventSalesError', ( { zones, event_id } ))
        return { ok: false, message: error}
    }

}

export const getEventSalesError = async ({ commit }, event_id) => {

    
        console.log("getEventSales - error -> actualizamos zonas")
        //Actualizar el error en todas las zonas
        commit('getEventSalesError', ( event_id  ))
        return { ok: true, message: "OK"}

}


export const getEventProposals = async ({ commit }, event_id) => {

    const accessToken = localStorage.getItem('accessToken')

    if(!accessToken) {
        //commit('logout')
        console.log("getEvents - error -> logout")
        console.log(error)
        commit('auth/logout', null, { root: true })
        return { ok: false, message: "ERROR"}
    }
    console.log('Action getEventProposals')
    console.log('       Event_id: ')
    console.log(event_id)
    try {
        console.log ('Getting Proposals...')
        const timer_inicio_consulta = window.performance.now()
        const config = { 
            headers: {"Authorization" : `Bearer ${accessToken}`},
            params: {"eventId" : event_id , "limit" : 60}
          }
        const query = 'https://management.staging.tiketblok.io/management' + '/proposals/' + config
        console.log ( query)
        const { data } = await authApi.get('proposals/',config)
        console.log(data)
        const timer_fin_consulta = window.performance.now()
        console.log ('Tiempo empleado para cargar PROPOSALS: ' + (timer_fin_consulta - timer_inicio_consulta))
        const proposals = data.data
        if(proposals.length>0){
            commit('getEventProposals',  { proposals, event_id })
        }
        else{
            commit('setEventProposalsError',  {  event_id })
            
        }
        return { ok: true, message: "OK"}

    } catch(error) {
        console.log("getEventProposals - error -> logout")
        console.log(error)
        //commit('auth/logout', null, { root: true })
        return { ok: false, message: "ERROR"}
    }

}

// Paginación de contenidos en memoria.
export const paginationBloks =  ({ commit }, {event_index, zone_index, inc}) => {

    console.log('Pagination(action): ' + event_index + ' , ' + zone_index + ' -> ' + inc)
    commit('paginationBloks', {event_index, zone_index, inc})
    

}

export const paginationProposals =  ({ commit }, {event_index, zone_index, inc}) => {

    console.log('Pagination(action): ' + event_index + ' , ' + zone_index + ' -> ' + inc)
    commit('paginationProposals', {event_index, zone_index, inc})
    

}