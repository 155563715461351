import { createStore } from 'vuex'

import auth from '../modules/auth/store'
import events from '../modules/events/store'
import proposals from '../modules/proposals/store'





const store = createStore({
    
    modules: {
        auth,
        events,
        proposals
        
    }
})




export default store