
import { computed } from 'vue'
import { useStore } from 'vuex'

const useAuth = () => {

    const store = useStore()

    const loginUser = async (user) => {
        const resp = await store.dispatch('auth/loginUser', user)
        return resp
    }

    const getUserInfo = async (user) => {
        const resp = await store.dispatch('auth/getUserInfo', user)
        return resp
    }

    const logout = () => {
        store.commit('auth/logout')
    }

    const checkAuthStatus = async () => {
        const resp = await store.dispatch('auth/checkAuthentication')
        return resp
    }

    // const refreshAuthentication = () => {
    //     const resp = store.dispatch('auth/refreshAuthentication')
    //     return resp
    // }
    // const checkAuthentication = () => {
    //     const resp = store.dispatch('auth/checkAuthentication')
    //     return resp
    // }

    return {
        loginUser,
        getUserInfo,
        logout,
        // refreshAuthentication,
        // checkAuthentication,
        checkAuthStatus,

        authStatus: computed(() => store.getters['auth/currentState']),
        personName: computed(() => store.getters['auth/personName'])

    }
}

export default useAuth