
import authApi from '@/api/authApi'
import cookie from 'vue-cookies'




export const loginUser = async ({ commit } , user) => {

    const { email, password} = user

    try {

        const { data } = await authApi.post ('session/login', {email, password, returnSecureToken: true})
        const { accessToken , refreshToken, id, expires } = data.data
        delete user.password
        commit('loginUser', { user, accessToken, refreshToken, id, expires})
        return { ok: true, message: "Welcome!"}

    } catch (error) {

        console.log(error.response)
        return { ok: false, message: "Error!"}
    }
}

export const getUserInfo = async ({ commit }) => {

    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const user = {
        name : '',
        surname: '',
        imageUrl: '',
        language: '', 
        email: ''
    }

    if(!accessToken) {
        commit('logout')
        router.push({ name: 'auth' })
        return { ok: false, message: 'No hay token'}
    }

    try {
        const { data } = await authApi.get('users/me', { headers: {"Authorization" : `Bearer ${accessToken}`} })
        const { name , surname, imageUrl, language, email, personName } = data.data
        commit('getUserInfo', { user, name , surname, imageUrl, language, email, personName})
        return { ok: true, message: "Welcome!"}
        

    } catch(error) {
        //commit('logout')
        return { ok: false, message: "ERROR"}
    }


}

export const checkAuthentication = async ({ commit }) => {

    const accessToken = localStorage.getItem('accessToken')
    const user = localStorage.getItem('user')

    

    if(!accessToken) {
        console.log('No hay token')
        commit('logout')
        return { ok: false, message: 'No hay token'}
    }
    // En caso de que haya accesstoken, aprovechamos para refrescarlo
    try{
        const actualRefreshToken = localStorage.getItem('refreshToken')
        const actualAccessToken = localStorage.getItem('accessToken')
        console.log ('Refrescando: ' + actualRefreshToken)
        const config = { 
            headers: {"Authorization" : `Bearer ${actualAccessToken}`},
            params: {"refreshToken" : actualRefreshToken}
          }
          console.log (config)
        const { data } = await authApi.post ('session/refresh', config)
        const {accessToken , refreshToken, id, expires} = data
        commit('loginUser', { user, accessToken , refreshToken, id, expires })
        return { ok: true }

    } catch (error) {
        console.log (error)
        //commit('logout')
        //Esto debería redirigir al login
        return { ok: true }
    }
}


// export const refreshAuthentication = async ({ commit }) => {

//     const accessToken = localStorage.getItem('accessToken')
//     const refreshToken = localStorage.getItem('refreshToken')

//     if(!accessToken) {
//         console.log('No hay token')
//         commit('logout')
//         return { ok: false, message: 'No hay token'}
//     }
    
//     try {
//         console.log('Llamando a refresh')
//         const { data } = await authApi.post ('session/refresh', { refreshToken })
//         // console.log('Fin llamada a refresh')
//         console.log(data.data)
//         //const { accessToken , refreshToken, id, expires } = data
//         // commit('loginUser', { user, accessToken, refreshToken, id, expires})
//         return { ok: true, message: "Welcome!"}
        

//     } catch(e) {
//         console.log('ERROR conectando con la API: ' )
//         commit('logout')
//         return { ok: false, message: 'error'}
//     }


// }