
export default () => ({

    status: 'authenticating', // 'authenticated', 'not-authenticated', 'authenticating'
    accessToken: null,
    refreshToken: null,
    expires: null,
    email: null,
    imageUrl: null,
    language: 'es',
    personName: null,
    surname: null
    
})