import store from '@/store'
import { def } from '@vue/shared'
import { refreshAuthentication } from '../store/actions'

const isAuthenticatedGuard = async ( to, from, next) =>{

    const {ok} = await store.dispatch('auth/checkAuthentication')
    console.log('Resultado de check: ' + ok)
    if (ok) next()
    else next( { name: 'login'} )
}

export default isAuthenticatedGuard