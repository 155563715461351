export const loginUser = (state, { user, accessToken, refreshToken, id, expires }) => {



    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
        state.refreshToken = refreshToken
    }

    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        state.accessToken = accessToken
    }

    if (id) {
        localStorage.setItem('id', id)
        state.id = id
    }
    if (expires) {
        localStorage.setItem('expires', expires)
        state.expires = expires
    }

    state.user = user
    state.status = 'authenticated'


}


export const getUserInfo = (state, { user, name, surname, imageUrl, language, email, personName }) => {


    if (name) {
        state.name = name
    }
    if (surname) {
        state.surname = surname
    }
    if (imageUrl) {
        state.imageUrl = imageUrl
    }
    if (language) {
        state.language = language
    }
    if (email) {
        state.email = email
    }
    if (personName) {
        state.personName = personName
    }
    //state.user = user
    state.status = 'authenticated'


}



export const logout = (state) => {
    state.user = null
    state.accessToken = null
    state.refreshToken = null
    state.status = 'not-authenticated'

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    
}