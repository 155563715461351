
export const currentState = ( state ) => {
    return state.status
   }
   
   export const personName = ( state ) => {
       return state.user?.personName || ''
      }
   
   
   
   