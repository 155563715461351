
export default {

    name: 'auth',
    component: () => import(/* webpackChunkName: "auth-layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
        {
            path: '',
            name: 'login',
            component: () => import(/* webpackChunkName: "auth - layout - login" */ '@/modules/auth/views/LoginView.vue'),
        }
        
    ]
}