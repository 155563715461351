
export default () => ({

  eventsList: [],
  eventsLoaded: {
    type: Boolean,
    default: false
  },
  activeEvent: {
    type: Object,
    default: null
  },
  singleEventLoaded: {
    type: Boolean,
    default: false
  },
  singleEvent: {
    type: Object,
    default: null
  }


})