<template>
  <nav>
    <div class="menu-item menu-item-events">
      <router-link to="/proposals">
        <div class="menu-image">
          <img src="@/assets/propuestas.svg" alt="Eventos" />
        </div>
      </router-link>
    </div>
    <div class="menu-item menu-item-test">
      <router-link to="/events">
        <div class="menu-image">
          <img src="@/assets/events.svg" alt="TEST" />
        </div>
      </router-link>
    </div>
    <div class="menu-item menu-item-login">
      <router-link to="/auth">
        <div class="menu-image">
          <img src="@/assets/settings.svg" alt="TEST" />
        </div>
      </router-link>
    </div>
  </nav>
  <div class="main">
    <router-view />

  </div>
</template>


<script>


import useAuth from './modules/auth/composables/useAuth'
export default {
  setup() {

    const { authStatus, refreshAuthentication, checkAuthentication } = useAuth()
    //checkAuthentication()

    return {
      authStatus
    }

  }
}

</script>

