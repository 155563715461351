import axios from 'axios'


const authApi = axios.create({
    //baseURL: 'https://management.development.tiketblok.io/management'
    baseURL: 'https://management.staging.tiketblok.io/management'
    
})

// console.log( process.env.NODE_ENV ) // TEST durante testing, 

export default authApi